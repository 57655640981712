import React from 'react';
import { SettingsApplications } from '@mui/icons-material';
const BaseConfigMngt = {
  name: 'Base Configuration',
  group: 'software',
  icon: <SettingsApplications sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/base-configuration-manager',
  exact: false,
  roles: ['system-configurator'],
  Application: () => {
    return (
      <iframe
        id="BaseConfigMngt"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/base-configuration-manager/"
        title="Base Configuration"
      />
    );
  },
};

export default BaseConfigMngt;
