import React, { useState } from 'react';
import { MessageBox } from '../components/messagebox';
import { CookieConsent } from '../cookieconsent-privacy/CookieConsent';
import { Box } from '@mui/material';
import Headersw from '../components/Headersw';
import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { navigateToLogin } from '../navigation/navigate';

const SHOW_MSGBOX = 1;
const SHOW_LOGIN = 2;
const SHOW_FORM = 3;

/**
 * Display a dialog to confirm the Thales consent's rules
 *
 */

export const CookieConsentContainer = () => {
  const [show, setShow] = useState(SHOW_FORM);
  const [searchParams] = useSearchParams();

  const msg = {
    value: 'PLEASE ACCEPT THE TERMS FIRST',
    severity: 'warning',
  };
  const handleLogin = (consent) => {
    if (consent) {
      setShow(SHOW_LOGIN);
      const redirect = searchParams?.get('redirect');
      navigateToLogin(redirect);
    } else {
      setShow(SHOW_MSGBOX);
    }
  };

  const sxStyle = {
    fontBox: {
      textTransform: 'uppercase',
      textAlign: 'center',
      color: '#030F77',
    },
    logo: {
      fontStyle: 'normal',
      letterSpacing: '0.7em',
      fontSize: '3.125em',
      fontWeight: '300',
      marginBottom: '-16px',
      marginLeft: '0.7em',
    },
    underLogo: {
      letterSpacing: '0.49em',
      fontSize: '1em',
      fontWeight: '400',
      marginLeft: '0.4em',
      marginTop: '1.6em',
    },
  };
  const sx = sxStyle;
  return (
    <>
      <Headersw />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
        minHeight="100vh"
      >
        <Box sx={sx.fontBox}>
          <Typography variant="h1" style={sx.logo}>
            flytedge
          </Typography>
          <Typography variant="h3" style={sx.underLogo}>
            the ifec game changer
          </Typography>
        </Box>
        <CookieConsent onLogin={handleLogin} />
        <MessageBox
          msg={msg}
          open={show === SHOW_MSGBOX}
          onClose={() => setShow(SHOW_FORM)}
        />
      </Box>
    </>
  );
};
