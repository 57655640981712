import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const AppButton = (props) => {
  const { title, icon } = props;
  const theme = useTheme();
  const isLargeHeader = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100px',
        marginBottom: '16px',
      }}
    >
      <Box
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '12px',
          flex: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: '#3642AA',
            width: '64px',
            height: '64px',
            borderRadius: '12px',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            justifySelf: 'center',
          }}
        >
          {icon}
        </Box>
      </Box>

      <Typography
        variant={isLargeHeader ? 'body1medium' : 'body2medium'}
        sx={{ textAlign: 'center' }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default AppButton;

AppButton.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
  appPath: PropTypes.string,
};
