import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WelcomeRouter from './WelcomeRouter';
import ApprovalRouter from './ApprovalRouter';
import HomeRouter from './HomeRouter';

const MainRouter = () => {
  return (
    <Routes>
      <Route path="welcome/*" element={<WelcomeRouter />} />
      <Route path="approval/*" element={<ApprovalRouter />} />
      <Route path="*" element={<HomeRouter />} />
    </Routes>
  );
};

export default MainRouter;
