import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { isConsentSet } from '../utils/userStorage';
import { CookieConsentContainer } from '../cookieconsent-privacy/CookieConsentContainer';
import { PrivacyContainer } from '../cookieconsent-privacy/PrivacyContainer';
import PrivacyContent from '../cookieconsent-privacy/PrivacyContent';
import { navigateToLogin } from './navigate';

export default function WelcomeRouter() {
  if (isConsentSet()) {
    navigateToLogin();
    return null;
  }

  return (
    <Routes>
      <Route path="privacy" element={<PrivacyContainer />} />
      <Route path="privacy-content" element={<PrivacyContent />} />
      <Route path="*" element={<CookieConsentContainer />} />
    </Routes>
  );
}
