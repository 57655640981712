import React from 'react';

const styles = {
  root: {
    padding: '88px 72px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  code: {
    color: 'var(--font-color-tertiary)',
    fontFamily: 'var(--font-primary)',
    fontSize: '5em',
    lineHeight: '1em',
    fontWeight: 'var(--font-weight-bold)',
    marginBottom: '0.25em',
  },
  details: {
    color: 'var(--font-color-primary)',
    fontFamily: 'var(--font-primary)',
    fontSize: '1.5em',
    lineHeight: '1em',
    fontWeight: 'var(--font-weight-regular)',
    marginBottom: '0.25em',
  },
};

const NoMatch = {
  name: 'NoMatch',
  path: '*',
  exact: false,
  Application: () => (
    <div style={styles.root}>
      <h1 style={styles.code}>404</h1>
      <p style={styles.details}>No application there…</p>
    </div>
  ),
};

export default NoMatch;
