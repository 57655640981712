import React from 'react';
import { ManageAccounts } from '@mui/icons-material';
const UserManagement = {
  name: 'Users',
  group: 'admin',
  icon: <ManageAccounts sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/user-management',
  exact: true,
  roles: ['human-user-admin'],
  Application: () => {
    return (
      <iframe
        id="UserManagement"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/user-management/admin"
        title="Users"
      />
    );
  },
};

export default UserManagement;
