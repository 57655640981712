import React from 'react';
import { Airlines } from '@mui/icons-material';
const OrganizationManagement = {
  name: 'Organizations',
  group: 'admin',
  icon: <Airlines sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/organizations',
  exact: true,
  roles: ['human-user-admin'],
  Application: () => {
    return (
      <iframe
        id="OrganizationManagement"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/user-management/organizations"
        title="Organizations"
      />
    );
  },
};

export default OrganizationManagement;
