import React, { useRef } from 'react';

import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';

import SideBarCommon from './SideBarCommon';
import { colors } from '@disruptive-ife/mui-quantum';

const drawerOpenWidth = '240px';
const drawerClosedWidth = '56px';

const openedMixin = (theme) => ({
  width: drawerOpenWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerOpenWidth,
  flexShrink: 0,
  whiteSpace: 'wrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const sx = {
  drawerOpen: (theme) => ({
    ...sx.drawer,
    '& .MuiDrawer-paper': {
      width: drawerOpenWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  drawerClose: (theme) => ({
    ...sx.drawer,
    '& .MuiDrawer-paper': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerClosedWidth,
    },
  }),
};

export default function SideBar({ drawerOpened, setDrawerOpened }) {
  const drawerContentChild = useRef();
  return (
    <StyledDrawer
      variant="permanent"
      open={drawerOpened}
      PaperProps={{ sx: { position: 'relative' } }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          backgroundColor: colors.white[100],
          overflow: 'auto',
        }}
      >
        <SideBarCommon
          ref={drawerContentChild}
          setDrawerOpened={setDrawerOpened}
          drawerOpened={drawerOpened}
        />
      </Box>
    </StyledDrawer>
  );
}
