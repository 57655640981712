import React from 'react';
import { MonitorHeart } from '@mui/icons-material';
import { useParams, useSearchParams } from 'react-router-dom';
const DeploymentMonitoring = {
  name: 'Deployment Monitoring',
  group: 'software',
  icon: <MonitorHeart sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/deployment-monitoring/*',
  exact: false,
  roles: ['fleet-manager'],
  Application: () => {
    const [searchParams] = useSearchParams();
    const params = useParams();

    const path = params['*'] === '*' ? '' : params['*'];
    const pathSearchParams =
      searchParams.size > 0 ? '/?' + searchParams.toString() : '';
    const src = `/apps/deployment-monitoring/${path}${pathSearchParams}`;

    return (
      <iframe
        id="DeploymentMonitoring"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src={src}
        title="Deployment Monitoring"
      />
    );
  },
};

export default DeploymentMonitoring;
