import React from 'react';
import { Tune } from '@mui/icons-material';
const ContentManagement = {
  name: 'Management',
  group: 'content',
  icon: <Tune sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/content-management',
  exact: true,
  roles: ['content-manager'],
  Application: () => {
    return (
      <iframe
        id="ContentManagement"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/content-management"
        title="Management"
      />
    );
  },
};

export default ContentManagement;
