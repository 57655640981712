export const navigateToHome = () => {
  window.location.href = '/';
};

export const navigateToApproval = () => {
  window.location.href = '/approval';
};

export const navigateToLogin = (redirect) => {
  let redirectParam = '';

  if (redirect) {
    const formattedRedirectParams =
      redirect.charAt(0) === '/' ? redirect.slice(1) : redirect;
    redirectParam = `?redirect=${encodeURIComponent(
      window.location.origin + '/' + formattedRedirectParams
    )}`;
  }
  window.location.href = `/v1/auth-service/login${redirectParam}`;
};

export const navigateToWelcome = (redirect) => {
  let redirectParam = '';

  if (redirect) {
    redirectParam = `?redirect=${encodeURIComponent(redirect)}`;
  }
  window.location.href = `/welcome${redirectParam}`;
};
