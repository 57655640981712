import { createContext } from 'react';

export const UserContext = createContext();

export const requireLogin = (user) => {
  return !user;
};

export const requireRolesAndOrgas = (user) => {
  return !(user?.roles?.length > 0 && user?.organizations?.length > 0);
};
