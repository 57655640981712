import React, { useContext } from 'react';
import Approval from '../approval/Approval';
import { navigateToHome, navigateToLogin, navigateToWelcome } from './navigate';
import { isConsentSet } from '../utils/userStorage';
import {
  UserContext,
  requireLogin,
  requireRolesAndOrgas,
} from '../utils/UserContext';

export default function ApprovalRouter() {
  const { user } = useContext(UserContext);

  if (requireLogin(user)) {
    if (isConsentSet()) {
      navigateToLogin();
    } else {
      navigateToWelcome();
    }
    return null;
  }

  if (!requireRolesAndOrgas(user)) {
    navigateToHome();
    return null;
  }

  return <Approval />;
}
