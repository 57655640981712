import React from 'react';
import PrivacyContent from '../cookieconsent-privacy/PrivacyContent';
import { Box, Link, Slide, Typography } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Hidden from '@mui/material/Hidden';
import Headersw from '../components/Headersw';
import { Link as RouterLink } from 'react-router-dom';
const sxStyle = {
  back: (theme) => ({
    [theme.breakpoints.down('xs')]: {
      // smartphone
      position: 'sticky',
      top: '40px',
      paddingLeft: '16px',
      height: '70px',
      width: '100%',
      zIndex: 1,
      backgroundColor: '#F6F9FB',
    },
    [theme.breakpoints.up('sm')]: {
      // tablet or desktop
      position: 'fixed',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      // tablet
      top: '44%',
    },
    [theme.breakpoints.up('lg')]: {
      // desktop
      top: '50%',
    },
  }),
  backArrow: (theme) => ({
    [theme.breakpoints.down('xs')]: {
      // smartphone
      fontSize: 24,
    },
    [theme.breakpoints.up('sm')]: {
      // tablet or desktop
      fontSize: 96,
    },
  }),
  backText: {
    fontWeight: 500,
    marginLeft: '4px',
  },
  privacyContent: (theme) => ({
    [theme.breakpoints.between('sm', 'md')]: {
      // tablet
      height: 'calc(100vh - 40px - 44px - 39px)',
      marginTop: '44px',
      marginLeft: '96px',
      marginRight: '24px',
      marginBottom: '39px',
    },
    [theme.breakpoints.up('lg')]: {
      // desktop
      height: 'calc(100vh - 40px - 144px - 64px)',
      maxWidth: '1417px',
      marginTop: '144px',
      marginLeft: '135px',
      marginRight: '16px',
    },
  }),
};

const renderBackButton = (sx) => {
  return (
    <Box display="flex" alignItems="center" sx={sx.back}>
      <Link component={RouterLink} to="/welcome" underline="none">
        <Box display="flex" alignItems="center">
          <KeyboardArrowLeft sx={sx.backArrow} />
          <Hidden smUp>
            <Typography variant="body1" sx={sx.backText}>
              Back
            </Typography>
          </Hidden>
        </Box>
      </Link>
    </Box>
  );
};

const withSlide = (children) => {
  return (
    <Slide
      in={true}
      timeout={400}
      direction={'left'}
      mountOnEnter
      unmountOnExit
    >
      {children}
    </Slide>
  );
};

export const PrivacyContainer = () => {
  const sx = sxStyle;

  return (
    <>
      <Headersw fullHeader />
      {withSlide(renderBackButton(sx))}
      {withSlide(
        <Box sx={sx.privacyContent}>
          <PrivacyContent />
        </Box>
      )}
    </>
  );
};
