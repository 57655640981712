export const storageKey = 'infinite-session';

export function initSessionAlive() {
  setSessionAlive();
  window.addEventListener('storage', () => {
    // When local storage has been modified in the context of another document
    isSessionAlive();
  });
}

export function setSessionAlive() {
  localStorage.setItem(storageKey, '1');
}

export function unsetSessionAlive() {
  localStorage.setItem(storageKey, '0');
}

export function isSessionAlive() {
  const value = localStorage.getItem(storageKey);
  if (value === '0') {
    // waiting for the end of the logout process in the others panels, then refresh this panel
    setTimeout(() => {
      window.location.reload();
    }, 4000);
  }
}
