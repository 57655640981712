import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { func } from 'prop-types';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { isConsentSet, setConsent, clearConsent } from '../utils/userStorage';

const sxStyle = {
  button: {
    textTransform: 'initial',
    borderRadius: '2px',
  },
  buttonGray: (theme) => ({
    textTransform: 'initial',
    backgroundColor: theme.palette.grey[300],
    ':hover': {
      backgroundColor: theme.palette.grey[400],
    },
  }),
  card: {
    maxWidth: '580px',
    minWidth: '400px',
    elevation: 1,
  },
  cardContent: {
    margin: '6px',
  },
  checkBox: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  title: {
    backgroundColor: '#13419B',
    color: 'white',
  },
  typo: {
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'left',
  },
  link: {
    fontWeight: 'bolder',
    color: '#13419B',
  },
};

/**
 * Display a dialog to confirm the Thales consent's rules
 *
 */
export const CookieConsent = ({ onLogin }) => {
  const sx = sxStyle;
  const [consentSet, setConsentSet] = useState(isConsentSet());

  const handleConsentClick = (event) => {
    setConsentSet(event.target.checked);
    if (event.target.checked) {
      setConsent();
    } else {
      clearConsent();
    }
  };

  return (
    <Card sx={sx.card} aria-label="cookie-consent-component">
      <CardHeader
        sx={sx.title}
        avatar={<InfoOutlinedIcon />}
        title="Before you continue"
        titleTypographyProps={{ variant: 'h7' }}
      />
      <CardContent sx={sx.cardContent}>
        <Typography variant="body1" sx={sx.typo}>
          Thales use cookies and manages your user account to give you the best
          experience on this website. We therefore need your consent to&nbsp;
          <Link
            component={RouterLink}
            sx={sx.link}
            to="/welcome/privacy"
            underline="always"
          >
            our privacy policy, term of use and cookies policies.
          </Link>
          Your consent is mandatory and refusal will result in failure to access
          our website and applications.
        </Typography>
        <FormControlLabel
          sx={sx.checkBox}
          control={
            <Checkbox
              aria-label="checkbox"
              data-testid="checkbox"
              name="checkbox"
              checked={consentSet}
              onChange={handleConsentClick}
            />
          }
          label="I accept Thales's privacy policy, terms of use and cookies policy"
        />
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end', padding: 16, gap: 8 }}>
        <Button
          aria-label="submit"
          sx={consentSet ? sx.button : sx.buttonGray}
          disableElevation={!consentSet}
          style={{ color: consentSet ? 'white' : '#797D9C' }}
          variant="contained"
          onClick={() => onLogin(consentSet)}
        >
          Sign In
        </Button>
      </CardActions>
    </Card>
  );
};

CookieConsent.propTypes = {
  onLogin: func,
};
