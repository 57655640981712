import React from 'react';

import { ReactComponent as ThalesLogo } from '../images/thales_logo.svg';
import { ReactComponent as InfiniteLogo } from '../images/small_infinite_logo.svg';
import { Paper, Box } from '@mui/material';

const sxStyle = {
  container: {
    position: 'sticky',
    top: '0px',
    zIndex: 2,
  },
  thalesLogo: {
    position: 'absolute',
    left: '16px',
    top: '6px',
  },
  headerPaper: (theme) => ({
    backgroundColor: 'white',
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      // smartphone
      justifyContent: 'flex-end',
      paddingRight: '16px',
    },
  }),
};

function Headersw({ fullHeader }) {
  const sx = sxStyle;

  return (
    <>
      <Box sx={sx.container}>
        <ThalesLogo style={sx.thalesLogo} />
        {fullHeader && (
          <Paper sx={sx.headerPaper} variant="elevation" square elevation={3}>
            <InfiniteLogo />
          </Paper>
        )}
      </Box>
    </>
  );
}

export default Headersw;
