import React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PrivacyContent from '../../cookieconsent-privacy/PrivacyContent';

const sx = {
  privacyContent: {
    height: 'calc(100vh - 40px - 144px - 64px)',
    width: '100%',
    maxWidth: '1417px',
    frameBorder: '0',
    marginLeft: '79px',
    marginTop: '144px',
    marginRight: '16px',
  },
  button: {
    border: '1px solid',
    borderColor: '#13419B',
    boxShadow: 'none',
    textTransform: 'none',
    width: '172px',
  },
  buttonUserAccount: {
    borderRadius: '32px 0px 0px 32px',
  },
  buttonTermsOfUse: {
    borderRadius: '0px 32px 32px 0px',
  },
};

const ProfileApp = () => {
  const [displayUserAccount, setDisplayUserAccount] = React.useState(false);

  function selectUserAccount(value) {
    setDisplayUserAccount(value);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <ButtonGroup
        sx={{
          justifyContent: 'flex-end',
          marginTop: '20px',
          marginRight: '20px',
        }}
        color="primary"
        variant="contained"
        disableElevation
      >
        <Button
          aria-label="user-account-button"
          variant={displayUserAccount ? 'contained' : 'text'}
          onClick={() => selectUserAccount(true)}
          sx={{ ...sx.button, ...sx.buttonUserAccount }}
        >
          User account
        </Button>
        <Button
          aria-label="terms-of-use-button"
          variant={displayUserAccount ? 'text' : 'contained'}
          onClick={() => selectUserAccount(false)}
          sx={{ ...sx.button, ...sx.buttonTermsOfUse }}
        >
          Terms of use
        </Button>
      </ButtonGroup>
      <Box sx={{ display: 'flex', flex: 1 }} aria-label="privacy-component">
        {displayUserAccount ? (
          <iframe
            id="UserAccount"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            aria-label="user-account-iframe"
            src="/apps/user-management/account/"
            title="User account"
          />
        ) : (
          <PrivacyContent />
        )}
      </Box>
    </Box>
  );
};

const Profile = {
  name: 'Profile',
  icon: AccountCircleIcon,
  path: '/app/profile',
  exact: true,
  roles: [],
  Application: ProfileApp,
};

export default Profile;
