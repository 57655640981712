import React from 'react';
import { DeveloperBoard } from '@mui/icons-material';
const LruStore = {
  name: 'LRU Store',
  group: 'maintenanceAndRepair',
  icon: <DeveloperBoard sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/lru-store',
  exact: true,
  roles: ['factory-operator', 'fleet-manager'],
  Application: () => {
    return (
      <iframe
        id="LRU Store"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/lru-store/"
        title="LRU Store"
      />
    );
  },
};

export default LruStore;
