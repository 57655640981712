import React, { useContext, forwardRef, useImperativeHandle } from 'react';
import {
  List,
  Box,
  Collapse,
  ListItemButton,
  Typography,
  Divider,
} from '@mui/material';
import { unsetSessionAlive } from '../sessionLifecycle';
import {
  ExpandLess,
  ExpandMore,
  Logout,
  AccountCircle,
  Dashboard,
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../utils/UserContext';
import { colors } from '@disruptive-ife/mui-quantum';

const justifyContentFlexStart = 'flex-start';
const primaryColor = colors.primary[500];

function isMenuItemActive(pathToTest, location) {
  return location.pathname.includes(pathToTest) ? true : false;
}

function isMenuSubItemActive(apps, appplicationGroupName) {
  const selectedApp = apps.applications.find((elem) =>
    location.pathname.includes(elem.path)
  );
  return selectedApp && selectedApp.group === appplicationGroupName
    ? true
    : false;
}

const BottomSideBar = ({ profile, location }) => {
  return (
    <List disablePadding>
      <Link
        to="/v1/auth-service/logout"
        onClick={unsetSessionAlive}
        style={{ textDecoration: 'none' }}
        reloadDocument
      >
        <ListItemButton sx={{ height: '55px', color: colors.bluegrey[800] }}>
          <Logout />
          {open && <Typography sx={{ marginLeft: '35px' }}>Logout</Typography>}
        </ListItemButton>
      </Link>
      <Link to={profile.path} style={{ textDecoration: 'none' }}>
        <ListItemButton
          sx={{ height: '55px', color: colors.bluegrey[800] }}
          selected={isMenuItemActive(profile.path, location)}
        >
          <AccountCircle
            sx={{
              color: isMenuItemActive(profile.path, location) && primaryColor,
            }}
          />
          {open && (
            <Typography
              variant={
                isMenuItemActive(profile.path, location)
                  ? 'body1medium'
                  : 'body1'
              }
              sx={{
                marginLeft: '35px',
                color: isMenuItemActive(profile.path, location) && primaryColor,
              }}
            >
              Account
            </Typography>
          )}
        </ListItemButton>
      </Link>
    </List>
  );
};

const DashboardSideBar = ({ home, location }) => {
  return (
    <List disablePadding>
      <Link style={{ textDecoration: 'none' }} to={home.path}>
        <ListItemButton
          aria-label={'dashboard'}
          selected={home.path === location.pathname ? true : false}
          sx={{
            height: '55px',
            color: colors.bluegrey[800],
          }}
        >
          <Dashboard
            sx={{
              color: home.path === location.pathname && primaryColor,
            }}
          />
          {open && (
            <Typography
              variant={
                home.path === location.pathname ? 'body1medium' : 'body1'
              }
              sx={{
                marginLeft: '35px',
                color: home.path === location.pathname && primaryColor,
              }}
            >
              Dashboard
            </Typography>
          )}
        </ListItemButton>
      </Link>
    </List>
  );
};

const ApplicationsSideBar = ({
  apps,
  location,
  updateMenuitemState,
  menuGroupState,
  setDrawerOpened,
  drawerOpened,
}) => {
  return (
    <List disablePadding>
      {apps.menuData.map((appplicationGroup) => (
        <Box key={appplicationGroup.title.group}>
          <ListItemButton
            sx={{
              height:
                appplicationGroup.title.name.length > 10 ? '65px' : '55px',
            }}
            selected={isMenuSubItemActive(apps, appplicationGroup.title.group)}
            onClick={(customEvent) => {
              if (
                drawerOpened ||
                (!drawerOpened &&
                  !isMenuSubItemActive(apps, appplicationGroup.title.group))
              ) {
                updateMenuitemState(customEvent, appplicationGroup.title.group);
              }
              setDrawerOpened(true);
            }}
          >
            <Box
              aria-label={appplicationGroup.title.name}
              sx={{
                flexDirection: 'row',
                display: 'flex',
                flex: 1,
                justifyContent: justifyContentFlexStart,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',

                  color:
                    isMenuSubItemActive(apps, appplicationGroup.title.group) &&
                    primaryColor,
                }}
              >
                {appplicationGroup.title.icon}
              </Box>
              {open && (
                <Typography
                  variant={
                    isMenuSubItemActive(apps, appplicationGroup.title.group)
                      ? 'body1medium'
                      : 'body1'
                  }
                  sx={{
                    marginLeft: '35px',
                    color:
                      isMenuSubItemActive(
                        apps,
                        appplicationGroup.title.group
                      ) && primaryColor,
                  }}
                >
                  {appplicationGroup.title.name}
                </Typography>
              )}
              {open &&
                (menuGroupState[appplicationGroup.title.group] ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ExpandLess
                      sx={{
                        color:
                          isMenuSubItemActive(
                            apps,
                            appplicationGroup.title.group
                          ) && primaryColor,
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ExpandMore
                      sx={{
                        color:
                          isMenuSubItemActive(
                            apps,
                            appplicationGroup.title.group
                          ) && primaryColor,
                      }}
                    />
                  </Box>
                ))}
            </Box>
          </ListItemButton>
          <Collapse
            in={drawerOpened && menuGroupState[appplicationGroup.title.group]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {appplicationGroup.childrenData.map((subApplication) => (
                <Link
                  to={subApplication.path}
                  key={subApplication.name}
                  style={{
                    textDecoration: 'none',
                    color: colors.bluegrey[500],
                  }}
                >
                  <ListItemButton
                    selected={isMenuItemActive(subApplication.path, location)}
                  >
                    <Typography
                      variant={
                        isMenuItemActive(subApplication.path, location)
                          ? 'body2medium'
                          : 'body2'
                      }
                      sx={{
                        marginLeft: '72px',
                        color:
                          isMenuItemActive(subApplication.path, location) &&
                          primaryColor,
                      }}
                    >
                      {subApplication.name}
                    </Typography>
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Collapse>
        </Box>
      ))}
    </List>
  );
};

const SideBarCommon = forwardRef((props, ref) => {
  const { apps } = useContext(UserContext);
  const [menuGroupState, setMenuGroupState] = React.useState(
    initializeMenuGroupState()
  );
  const home = apps.home;
  const profile = apps.basicApps.find((app) => app.name === 'Profile');
  const location = useLocation();

  function initializeMenuGroupState() {
    return Object.fromEntries(
      apps.menuData.map((key) => [
        key.title.group,
        isMenuSubItemActive(apps, key.title.group),
      ])
    );
  }

  React.useEffect(() => {
    if (!props.drawerOpened) {
      ref.current.resetMenuGroupState();
    }
  }, [props.drawerOpened]);

  useImperativeHandle(ref, () => ({
    resetMenuGroupState() {
      const newState = {};
      for (const property in menuGroupState) {
        newState[property] = isMenuSubItemActive(apps, property);
      }
      setMenuGroupState(newState);
    },
  }));

  function updateMenuitemState(customEvent, menuItemGroupName) {
    const menuGroupStateUpdated = { ...menuGroupState };
    menuGroupStateUpdated[menuItemGroupName] =
      !menuGroupStateUpdated[menuItemGroupName];
    setMenuGroupState(menuGroupStateUpdated);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: justifyContentFlexStart,
        }}
      >
        <DashboardSideBar home={home} location={location} />
        <Divider />
        <ApplicationsSideBar
          apps={apps}
          home={home}
          location={location}
          updateMenuitemState={updateMenuitemState}
          menuGroupState={menuGroupState}
          setDrawerOpened={props.setDrawerOpened}
          drawerOpened={props.drawerOpened}
        />
      </Box>
      <BottomSideBar profile={profile} location={location} />
    </Box>
  );
});

SideBarCommon.displayName = 'SideBarCommon';
export default SideBarCommon;
