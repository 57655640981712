/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */

import React from 'react';
import { Paper, Link, Box, Typography, Divider } from '@mui/material';
import Hidden from '@mui/material/Hidden';

const sxStyle = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
  },
  tableOfContentPaper: {
    position: 'sticky',
    top: '0px',
    minWidth: '273px',
    padding: '16px',
    marginRight: '16px',
    backgroundColor: 'white',
  },
  tableOfContentTitle: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'double',
    // textUnderlineOffset needs to be set with unit (integers do not work)
    textUnderlineOffset: '0.25rem',
    marginBottom: '32px',
  },
  tableOfContentDivider: {
    marginTop: '32px',
    marginBottom: '16px',
  },
  tableOfContentLink: {
    textDecorationLine: 'none',
    '&:hover': { textDecorationLine: 'underline' },
  },
  contentPaper: {
    padding: '16px',
    backgroundColor: 'white',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  titleSpacingTop: {
    marginTop: '48px',
  },
  titleSpacingBottom: {
    marginBottom: '16px',
  },
  divider: {
    marginTop: '16px',
    marginBottom: '24px',
  },
};

function scrollTo(id) {
  document
    .querySelector('[id="' + id + '"]')
    .scrollIntoView({ behavior: 'smooth' });
}

const TableOfContent = (props) => {
  const sx = props.sx;

  return (
    <Paper elevation={0} sx={sx.tableOfContentPaper}>
      <Typography sx={sx.tableOfContentTitle} variant="h6">
        TABLE OF CONTENT
      </Typography>
      <Typography variant="h6">
        <Link
          sx={sx.tableOfContentLink}
          onClick={() => scrollTo('privacyNotice')}
        >
          Privacy Notice
        </Link>
      </Typography>
      <Typography variant="h6">
        <Link sx={sx.tableOfContentLink} onClick={() => scrollTo('termsOfUse')}>
          Terms of use
        </Link>
      </Typography>
      <Typography variant="h6">
        <Link sx={sx.tableOfContentLink} onClick={() => scrollTo('cookies')}>
          Cookies
        </Link>
      </Typography>
      <Divider sx={sx.tableOfContentDivider} />
      <Typography variant="h6">
        <Link sx={sx.tableOfContentLink} onClick={() => scrollTo('contactUs')}>
          How to contact us
        </Link>
      </Typography>
    </Paper>
  );
};

const Content = (props) => {
  const sx = props.sx;

  return (
    <Paper sx={sx.contentPaper} elevation={0}>
      <Typography
        sx={sx.titleSpacingBottom}
        variant="h6"
        color="primary"
        id="privacyNotice"
      >
        Privacy Notice
      </Typography>
      <Typography variant="body1" align="justify">
        Thales Infinite platform is a{' '}
        <b>Thales Avionics, Inc., InFlyt Experience</b> product, which is a{' '}
        <b>Thales AVS France SAS</b> company. This privacy policy explains what
        personal information we use for our Infinite website, and how our
        company is using this personal information to provide a service.
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" align="justify">
            What data do we collect: first name, last name, email address (only
            if user-related data collection have been enabled), company you
            belong to, roles inside the organisation, user's device and OS,
            geolocation, user's activity (action, navigation and views) and
            sessions metrics (if enabled).
          </Typography>
        </li>
        <li>
          <Typography variant="body1" align="justify">
            How do we collect: upon user sign-up, by getting the information
            either directly from user, or through its OIDC provider.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" align="justify">
            How your data is used: your data will be used only in the frame of
            the IFEC services provided by the platform. Your name will be used
            to build your profile and provide you with a minimum of
            personalization in the interface, your email address is used for the
            only purpose of being able to contact you for granting you access or
            sending alerts. Company and role info are used in order to provide
            the exact services you need and want access to on the platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" align="justify">
            How data is stored: personal account information is handled through
            a managed service by Microsoft Azure.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" align="justify">
            Marketing: no Personal information will be used for marketing
            purposes.
          </Typography>
        </li>
      </ul>
      <Typography
        sx={{ ...sx.titleSpacingTop, ...sx.titleSpacingBottom }}
        variant="h6"
        color="primary"
        id="termsOfUse"
      >
        Terms of use
      </Typography>
      <Typography variant="body1" align="justify">
        Infinite platform, also referred as infinite website, provides a mean to
        interact with your Infinite IFEC product and ecosystem.
        <br />
        It provides a suite to monitor your fleets, push updates, design
        upgrades throughout the life of your product, add/remove onboard
        services, and access all types of analytics regarding the experience of
        passengers.
        <br />
        As such Thales expects users of the platform to use the tool
        respectfully of their needs. Any actions that would be detected as
        tentative to break the service, or get privileged access beyond what was
        granted to the user by Thales, will result in an interruption of service
        for the given user.
      </Typography>
      <Typography
        sx={{ ...sx.titleSpacingTop, ...sx.titleSpacingBottom }}
        variant="h6"
        color="primary"
        id="cookies"
      >
        Cookies
      </Typography>
      <Typography variant="body1" align="justify">
        Here is a description of cookies used:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" align="justify">
            connect.sid: holds the session id that allows the user to access the
            different applications provided by Infinite. This cookie is
            compulsory and the only way for Infinite website to grant you access
            to the different applications available from the portal.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" align="justify">
            Preference: to hold cookie preferences.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" align="justify">
            Azure ADB2C cookies: Thales relies on Microsoft Azure AD B2C
            solution for handling its user base. As such it uses cookies
            required by the service and best described by Microsoft
            documentation itself:{' '}
            <Link
              href="https://docs.microsoft.com/en-us/azure/active-directory-b2c/cookie-definitions"
              underline="always"
            >
              https://docs.microsoft.com/en-us/azure/active-directory-b2c/cookie-definitions.
            </Link>
            <br />
          </Typography>
        </li>
      </ul>
      <Typography variant="body1" align="justify">
        These cookies are compulsory and refusing them will result in failure to
        access our website and applications.
      </Typography>
      <Divider sx={sx.divider} />
      <Typography variant="h6" color="primary" id="contactUs">
        How to contact us
      </Typography>
      <Typography variant="body1" align="justify">
        E-mail us at{' '}
        <Link href="mailto:infinite-ifec@thalesdigital.io" underline="always">
          infinite-ifec@thalesdigital.io
        </Link>
        .
      </Typography>
    </Paper>
  );
};

const PrivacyContent = () => {
  const sx = sxStyle;

  return (
    <Box sx={sx.container}>
      <Hidden smDown>
        <TableOfContent sx={sx} />
      </Hidden>
      <Content sx={sx} />
    </Box>
  );
};

export default PrivacyContent;
