import axios from 'axios';
import { useQuery } from 'react-query';

export const useUserInfo = () => {
  return useQuery(
    'user-info',
    () =>
      axios({
        method: 'get',
        url: '/v1/user-info/me',
      }),
    {
      select: (response) => response.data,
      retry: false,
      refetchInterval: 30_000,
    }
  );
};
