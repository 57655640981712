import React from 'react';
import { Extension } from '@mui/icons-material';
const ServiceRelease = {
  name: 'Release Composer',
  group: 'software',
  icon: <Extension sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/service-release',
  exact: true,
  roles: ['software-manager'],
  Application: () => {
    return (
      <iframe
        id="ServiceRelease"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/service-release/"
        title="Release Composer"
      />
    );
  },
};

export default ServiceRelease;
