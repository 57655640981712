import React from 'react';
import { Map as MapIcon } from '@mui/icons-material';
const FleetMap = {
  name: 'Map',
  group: 'fleet',
  icon: <MapIcon sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/fleet-map',
  exact: true,
  roles: ['fleet-manager'],
  Application: () => {
    return (
      <iframe
        id="FleetMap"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/fleet-map/"
        title="Map"
      />
    );
  },
};

export default FleetMap;
