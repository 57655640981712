import React from 'react';
import { FactCheck } from '@mui/icons-material';
const ServicePublication = {
  name: 'Publication',
  group: 'software',
  icon: <FactCheck sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/service-publication',
  exact: true,
  roles: ['service-provider'],
  Application: () => {
    return (
      <iframe
        id="SoftwarePublication"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/service-publication/"
        title="Publication"
      />
    );
  },
};

export default ServicePublication;
