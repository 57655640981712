import React from 'react';
import { Exposure } from '@mui/icons-material';

const AircraftRegistry = {
  name: 'Registry',
  group: 'fleet',
  icon: <Exposure sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/aircraft-registry',
  exact: true,
  roles: ['fleet-manager'],
  Application: () => {
    return (
      <iframe
        id="AircraftRegistry"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/aircraft-registry/"
        title="Registry"
      />
    );
  },
};

export default AircraftRegistry;
