import React from 'react';
import { LockOpen } from '@mui/icons-material';

const AircraftSecrets = {
  name: 'Aircraft Secrets',
  group: 'fleet',
  icon: <LockOpen sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/aircraft-secrets',
  exact: true,
  roles: ['system-configurator'],
  Application: () => {
    return (
      <iframe
        id="AircraftSecrets"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/aircraft-secrets/"
        title="Aircraft Secrets"
      />
    );
  },
};

export default AircraftSecrets;
