const storageKey = 'consent';

export const setConsent = () => {
  localStorage.setItem(storageKey, '1');
};

export const clearConsent = () => {
  localStorage.removeItem(storageKey);
};

export const isConsentSet = () => {
  const value = localStorage.getItem(storageKey);
  return value !== null;
};
