import { datadogRum } from '@datadog/browser-rum';

import axios from 'axios';

export async function initDatadogRum() {
  try {
    const { data } = await axios.get('/datadog/datadog-rum.json');
    datadogRum.init({
      applicationId: data.applicationId,
      clientToken: data.clientToken,
      site: data.site,
      service: 'frontend',
      env: data.env,
      sampleRate: 100,
      trackInteractions: true,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
