import { useQuery } from 'react-query';
import axios from 'axios';

export const useGetOrga = () => {
  return useQuery(
    'organizations',
    () =>
      axios({
        method: 'get',
        url: '/v1/user-info/organizations',
      }),
    {
      select: (response) => {
        return response.data;
      },
    }
  );
};
