import React, { useContext } from 'react';
import { UserContext } from '../../utils/UserContext';
import AppGroup from './AppGroup';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Home() {
  const { apps } = useContext(UserContext);
  const theme = useTheme();
  const isLargeHeader = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      sx={() => ({
        paddingBottom: '42px',
        [theme.breakpoints.up('xs')]: {
          marginLeft: '16px',
          marginTop: '12px',
          width: '328px',
        },
        [theme.breakpoints.up('sm')]: {
          marginLeft: '106px',
          marginTop: '55px',
          width: '460px',
        },
        [theme.breakpoints.up('md')]: {
          marginLeft: '124px',
          width: '524px',
        },
        [theme.breakpoints.up('lg')]: {
          marginLeft: '207px',
          marginTop: '58px',
          width: '536px',
        },
      })}
    >
      <Typography
        variant={isLargeHeader ? 'title2' : 'subtitle1'}
        sx={() => ({
          [theme.breakpoints.up('xs')]: {
            marginBottom: '16px',
          },
          [theme.breakpoints.up('sm')]: {
            marginBottom: '49px',
          },
        })}
      >
        Dashboard
      </Typography>
      {apps.menuData.map((appGroup) => (
        <AppGroup
          appGroupTitle={appGroup.title.name}
          appGroupIcon={appGroup.title.icon}
          appsData={appGroup.childrenData}
          key={appGroup.title.name}
        />
      ))}
    </Box>
  );
}

export default Home;
