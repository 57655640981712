import React from 'react';

import { ReactComponent as HourGlass } from '../images/hourglass.svg';
import Headersw from '../components/Headersw';
import { Box, Typography } from '@mui/material';
import { navigateToLogin } from '../navigation/navigate';

const sx = {
  approvalHourglass: {
    height: '50%',
  },
  content: {
    position: 'relative',
    marginTop: '22vh',
    marginLeft: '12vw',
    marginRight: '12vw',
    height: '47vh',
  },
  approvalText: {
    zIndex: 1,
    padding: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
};

function Approval() {
  setTimeout(() => {
    navigateToLogin();
  }, 30_000);
  return (
    <>
      <Headersw />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        aria-label="approval-component"
      >
        <Box sx={sx.content} display="flex" alignItems="center">
          <HourGlass display="flex" style={sx.approvalHourglass} />
          <Typography variant="h4" sx={sx.approvalText}>
            Dear client,
            <br />
            <br />
            Your access to FlytEdge is being processed by our teams.
            <br />
            You’ll be informed by email once your access is granted.
            <br />
            <br />
            Thank you.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Approval;
