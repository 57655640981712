import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { initSessionAlive } from './sessionLifecycle';
import { initDatadogRum } from './datadog/rum';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';

initDatadogRum();
initSessionAlive();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.querySelector('#root')
);
