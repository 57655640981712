import React from 'react';
import { ReactComponent as Vac } from '../../images/vac.svg';

const VirtualAircraft = {
  name: 'Virtual Aircraft',
  group: 'fleet',
  icon: <Vac sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/virtual-aircraft',
  exact: true,
  roles: ['fleet-manager'],
  Application: () => {
    return (
      <iframe
        id="VirtualAircraft"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/virtual-aircraft/"
        title="Virtual Aircraft"
      />
    );
  },
};

export default VirtualAircraft;
