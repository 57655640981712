import React, { useRef } from 'react';
import { Box, Drawer } from '@mui/material';
import SideBarCommon from './SideBarCommon';

export default function SideBarMini({ drawerOpened, setDrawerOpened }) {
  const drawerContentChild = useRef();
  return (
    <Drawer
      sx={{ '& .MuiDrawer-paper': { width: '240px' } }}
      onClose={() => setDrawerOpened(false)}
      open={drawerOpened}
      PaperProps={{ sx: { position: 'relative' } }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ height: '48px' }}></Box>
        <SideBarCommon
          ref={drawerContentChild}
          setDrawerOpened={setDrawerOpened}
          drawerOpened={drawerOpened}
        />
      </Box>
    </Drawer>
  );
}
