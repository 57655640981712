import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import { UserContext } from '../utils/UserContext';
import './AppRouter.css';
import { colors } from '@disruptive-ife/mui-quantum';

const AppRouter = () => {
  const { apps } = useContext(UserContext);

  const appList = [apps.home, apps.basicApps, apps.applications, apps.noMatch];

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
        bgcolor: colors.bluegrey[25],
      }}
    >
      <Routes>
        {appList
          .flat()
          .map(
            (app) =>
              app?.path && (
                <Route
                  key={app.name}
                  exact={app.exact}
                  path={app.path}
                  element={<app.Application />}
                />
              )
          )}
      </Routes>
    </Box>
  );
};

export default AppRouter;
