import React from 'react';
import AppButton from './AppButton';
import PropTypes from 'prop-types'; // ES6
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import { colors } from '@disruptive-ife/mui-quantum';
const AppGroup = (props) => {
  const { appGroupTitle, appGroupIcon, appsData } = props;
  const theme = useTheme();
  const isLargeHeader = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'nowrap',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {appGroupIcon}
        <Typography
          variant={isLargeHeader ? 'subtitle1' : 'subtitle2'}
          sx={{ marginLeft: '19px' }}
        >
          {appGroupTitle}
        </Typography>
      </Box>
      <Divider />

      <Box sx={{ flexGrow: 1, marginTop: '24px', marginBottom: '40px' }}>
        <Grid container>
          {appsData.map((app) => {
            return (
              <Grid key={app.name}>
                <Link
                  aria-label={'dashboard-button-' + app.name}
                  to={app.path}
                  style={{
                    textDecoration: 'none',
                    color: colors.bluegrey[800],
                  }}
                >
                  <AppButton title={app.name} icon={app.icon} />
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default AppGroup;

AppGroup.propTypes = {
  appGroupTitle: PropTypes.string,
  appGroupIconName: PropTypes.string,
  appsData: PropTypes.array,
};
