import React, { useMemo } from 'react';
import MainRouter from './navigation/MainRouter';
import { UserContext } from './utils/UserContext';
import { getApplications } from './applications';
import { useUserInfo } from './api/useGetUserInfo';
import { useLocation } from 'react-router-dom';
import { navigateToLogin } from './navigation/navigate';
import { isConsentSet } from './utils/userStorage';
import quantum from '@disruptive-ife/mui-quantum';
import { ThemeProvider } from '@mui/material';

export default function App() {
  const { data, isLoading, isError, isFetched } = useUserInfo();
  const location = useLocation();
  const context = useMemo(
    () => ({
      apps: [],
    }),
    []
  );

  if (data) {
    context.user = data;
    context.apps = getApplications(data);
  }

  if (isError && isFetched && !isLoading && isConsentSet && data) {
    navigateToLogin(location.pathname);
  }

  return (
    <ThemeProvider theme={quantum}>
      <UserContext.Provider value={context}>
        {!isLoading && <MainRouter />}
      </UserContext.Provider>
    </ThemeProvider>
  );
}
