import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import SideBarMini from '../components/SideBarMini';
import AppRouter from './AppRouter';
import {
  navigateToLogin,
  navigateToWelcome,
  navigateToApproval,
} from './navigate';
import { isConsentSet } from '../utils/userStorage';
import {
  UserContext,
  requireLogin,
  requireRolesAndOrgas,
} from '../utils/UserContext';
import { Box, useMediaQuery } from '@mui/material';
export default function HomeRouter() {
  const { user } = useContext(UserContext);
  const params = useParams();
  const isLargeTopbar = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const [drawerOpened, setDrawerOpened] = useState(isLargeTopbar);

  if (requireLogin(user)) {
    const redirect = params['*'];

    if (isConsentSet()) {
      navigateToLogin(redirect);
    } else {
      navigateToWelcome(redirect);
    }

    return null;
  }

  if (requireRolesAndOrgas(user)) {
    navigateToApproval();

    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: ' flex',
        flexDirection: 'column',
      }}
    >
      <Header drawerOpened={drawerOpened} setDrawerOpened={setDrawerOpened} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        {isLargeTopbar ? (
          <SideBar
            drawerOpened={drawerOpened}
            setDrawerOpened={setDrawerOpened}
          />
        ) : (
          <SideBarMini
            drawerOpened={drawerOpened}
            setDrawerOpened={setDrawerOpened}
          />
        )}
        <AppRouter />
      </Box>
    </Box>
  );
}
