import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import React from 'react';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { bool, func, shape, string } from 'prop-types';
const sx = {
  root: (theme) => ({
    color: 'black',
    backgroundColor: theme.palette.warning.main,
  }),
};

/**
 * Display a popup messagebox on the bottom
 *
 * @param ({severity, value}) msg   the error msg. Contains attributes severity and value. value is null if no message
 * @param {boolean}             open  say if open the msg box. If not specified, msgBox open if msg !== undefined
 * @returns {JSX.Element}
 */
export const MessageBox = ({ msg, onClose = (f) => f, open = true }) => (
  <Snackbar
    open={open}
    TransitionComponent={TransitionUp}
    autoHideDuration={4000}
    onClose={onClose}
    aria-label="MessageBox"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Paper elevation={3}>
      <Alert
        severity={msg?.severity}
        icon={false}
        aria-label="alert"
        sx={sx.root}
      >
        {msg?.value}
      </Alert>
    </Paper>
  </Snackbar>
);

function TransitionUp(props) {
  return <Slide {...props} direction="up" timeout={1000} />;
}

export const MsgType = {
  severity: string.isRequired,
  value: string,
};

MessageBox.propTypes = {
  msg: shape(MsgType),
  onClose: func,
  open: bool,
};
