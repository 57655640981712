import {
  Home as HomeIcon,
  Flight,
  Terminal,
  SmartDisplay,
  SupervisorAccount,
  Construction,
} from '@mui/icons-material';
import Home from './home/Home';
import NoMatch from './no-match/NoMatch';
import FleetMonitor from './fleet-monitoring/FleetMonitoring';
import ServiceDeployment from './service-deployment/ServiceDeployment';
import ServiceRelease from './service-release/ServiceRelease';
import LruStore from './lru-store/LruStore';
import Profile from './profile/Profile';
import UserManagement from './user-management/UserManagement';
import FleetMap from './fleet-map/FleetMap';
import ContentPublication from './content-publication/ContentPublication';
import ContentManagement from './content-management/ContentManagement';
import AircraftRegistry from './aircraft-registry/AircraftRegistry';
import ServicePublication from './service-publication/ServicePublication';
import BaseConfigMngt from './base-configuration-management/BaseConfigMngt';
import ServiceBulletins from './service-bulletins/ServiceBulletins';
import AircraftSecrets from './aircraft-secrets/AircraftSecrets';
import React from 'react';
import DeploymentMonitoring from './deployment-monitoring/DeploymentMonitoring';
import VirtualAircraft from './virtual-aircraft/VirtualAircraft';
import OrganizationManagement from './organization-management/OrganizationManagement';

const homeApp = {
  name: 'Home',
  icon: HomeIcon,
  path: '/',
  exact: true,
  Application: Home,
};
const basicApps = [Profile];
const applications = [
  FleetMonitor,
  FleetMap,
  ServicePublication,
  ServiceRelease,
  ServiceDeployment,
  LruStore,
  UserManagement,
  OrganizationManagement,
  ContentPublication,
  ContentManagement,
  AircraftRegistry,
  BaseConfigMngt,
  ServiceBulletins,
  AircraftSecrets,
  DeploymentMonitoring,
  VirtualAircraft,
];

const titles = [
  { group: 'fleet', name: 'Fleet', icon: <Flight /> },
  { group: 'software', name: 'Software', icon: <Terminal /> },
  { group: 'content', name: 'Content', icon: <SmartDisplay /> },
  { group: 'admin', name: 'Admin', icon: <SupervisorAccount /> },
  {
    group: 'maintenanceAndRepair',
    name: 'Maintenance & Repair',
    icon: <Construction />,
  },
];

function hasSome(l1, l2) {
  return l2.some((e) => l1.includes(e));
}

function filterApps(apps, userRoles) {
  return apps.filter((app) => hasSome(userRoles, app.roles));
}

export function getApplications(user) {
  const genApps = {
    home: homeApp,
    basicApps: basicApps,
    noMatch: NoMatch,
    titles: titles,
  };
  genApps.applications = user?.id ? filterApps(applications, user.roles) : [];
  genApps.menuData = sortAppByName(genApps.applications);

  return genApps;
}

export function mergeByGroup(arrayA, arrayB) {
  const mergedList = arrayA;

  for (const element of arrayA) {
    element.title = arrayB.find((e) => e.group === element.group).name;
  }
  return mergedList;
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  for (const item of list) {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (collection) {
      collection.push(item);
    } else {
      map.set(key, [item]);
    }
  }
  return map;
}

function sortAppByName(applicationsList) {
  const data = [];
  for (const title of titles) {
    const appGrouped = applicationsList.filter((app) => {
      return app.group === title.group;
    });
    if (appGrouped.length > 0) {
      const row = { title: title, childrenData: appGrouped };
      data.push(row);
    }
  }
  return data;
}
