import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
const ServiceBulletins = {
  name: 'Service Bulletins',
  group: 'software',
  icon: <DescriptionOutlinedIcon sx={{ color: '#FFFFFF' }} fontSize="large" />,
  path: '/app/service-bulletins',
  exact: false,
  roles: ['system-configurator', 'fleet-manager'],
  Application: () => {
    return (
      <iframe
        id="ServiceBulletins"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src="/apps/base-configuration-manager/service-bulletins"
        title="Service Bulletins"
      />
    );
  },
};

export default ServiceBulletins;
