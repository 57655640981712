import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  Select,
  Box,
  Toolbar,
  AppBar,
  Typography,
  Popover,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ReactComponent as ThalesLogoFull } from '../resources/thales-icon-full.svg';
import { ReactComponent as ThalesLogoMini } from '../resources/thales-icon-mini.svg';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useUserInfo } from '../api/useGetUserInfo';
import { useGetOrga } from '../api/useGetOrgas';

import { getCookieValue, setCookie } from '../utils/cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { colors } from '@disruptive-ife/mui-quantum';

const FLYTEDGE_ORG = 'flytedge-org';

function updateCookies(value) {
  setCookie(FLYTEDGE_ORG, value, window.location.hostname);
}

const getDaySuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1: {
      return 'st';
    }
    case 2: {
      return 'nd';
    }
    case 3: {
      return 'rd';
    }
    default: {
      return 'th';
    }
  }
};

function Header({ drawerOpened, setDrawerOpened }) {
  const [organizationsList, setOrganizationList] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [ctime, setTime] = useState(Date.now());

  initializeCookieListenerUpdater();
  const { data, isError, isFetched } = useUserInfo();
  const { data: orgaData } = useGetOrga();
  const theme = useTheme();

  const isLargeTopbar = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));

  function toggleMenuDrawer() {
    setDrawerOpened(!drawerOpened);
  }

  const headerVariant = () => {
    if (isLargeTopbar) {
      return 'subtitle2';
    } else if (isSmall) {
      return 'body2';
    } else {
      return 'caption1';
    }
  };

  const getTimeStrings = () => {
    const date = new Date(ctime);
    const dayOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
    const dayNumber = date.getDate();
    const localDay = dayOfWeek[date.getDay()];
    const locale = navigator.language;
    const isEn = /^en/i.test(locale);

    const timeOptions = {
      hour12: isEn,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    const localTime = date.toLocaleTimeString(locale, timeOptions);
    const utcTime = date.toLocaleTimeString(locale, {
      ...timeOptions,
      timeZone: 'UTC',
    });

    const utcDay = dayOfWeek[date.getUTCDay()];
    const localDaySuffix = getDaySuffix(dayNumber);

    // Set the state with the formatted time and day
    return {
      localTime: `${localDay} ${dayNumber}${localDaySuffix} - ${localTime}`,
      UTCTime: `${utcDay} ${dayNumber}${localDaySuffix} - ${utcTime}`,
    };
  };

  const updateTime = () => {
    setTime(Date.now());
  };

  useEffect(() => {
    if (isFetched && !isError && data.organizations.length > 0) {
      setOrganizationList(data.organizations);
      const previousSelectedOrganization = getCookieValue(FLYTEDGE_ORG);
      if (
        previousSelectedOrganization &&
        organizationsList.includes(previousSelectedOrganization)
      ) {
        setSelectedOrganization(previousSelectedOrganization);
        updateCookies(previousSelectedOrganization);
      } else if (organizationsList[0]) {
        setSelectedOrganization(organizationsList[0]);
        updateCookies(organizationsList[0]);
      }
    }
    updateTime();
    const intervalTime = setInterval(updateTime, 1000);
    return () => clearInterval(intervalTime);
  }, [data.organizations, isFetched, isError, data, organizationsList]);

  function initializeCookieListenerUpdater() {
    window.addEventListener('storage', (event) => {
      if (
        event.storageArea !== localStorage ||
        event.target.origin !== window.location.origin
      ) {
        return;
      }
      if (event.key === FLYTEDGE_ORG) {
        updateCookieForSelectedOrganisation(event.newValue);
      }
    });
  }

  const handleChange = (event) => {
    if (event.target.value !== selectedOrganization) {
      updateCookieForSelectedOrganisation(event.target.value);
    }
  };

  function updateCookieForSelectedOrganisation(cookieValue) {
    setSelectedOrganization(cookieValue);
    updateCookies(cookieValue);
    window.location.reload();
    window.localStorage.setItem(FLYTEDGE_ORG, cookieValue);
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for Popover
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the Popover
  };

  const open = Boolean(anchorEl);

  const organizationOptions =
    orgaData && Array.isArray(orgaData)
      ? orgaData.map((org) => ({
          label: org.name ?? `[${org.id}]`,
          id: org.id,
        }))
      : [];
  const timeStrings = getTimeStrings();
  return (
    <AppBar
      sx={{
        height: isLargeTopbar ? '64px' : '48px',
        backgroundColor: colors.white[100],
        zIndex: (themeCustom) => themeCustom.zIndex.drawer + 1,
      }}
      color="default"
      position="relative"
    >
      <Toolbar
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: '0px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(!isSmall && { pl: 1 }),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={toggleMenuDrawer}
            sx={
              drawerOpened
                ? { marginRight: 2 }
                : { marginRight: 2, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
            }
            data-testid="bgm"
            aria-label="bgm"
          >
            <MenuIcon />
          </IconButton>
          {isLargeTopbar ? (
            <ThalesLogoFull />
          ) : (
            <ThalesLogoMini sx={{ marginRight: 2 }} />
          )}

          <Typography
            variant={headerVariant()}
            sx={{ marginLeft: isLargeTopbar ? '32px' : '12px', mr: 1 }}
          >
            FlytEdge
          </Typography>
          {isLargeTopbar ? (
            <Box
              sx={{
                height: '23px',
                borderRight: '2px solid #DEDEE6',
                marginRight: '40px',
                marginLeft: '40px',
              }}
            />
          ) : (
            <Box
              sx={{
                height: '23px',
                borderRight: '2px solid #DEDEE6',
                marginRight: '16px',
                marginLeft: '16px',
              }}
            />
          )}
          <Select
            IconComponent={KeyboardArrowDownIcon}
            onChange={handleChange}
            value={selectedOrganization}
            inputProps={{ 'data-testid': 'organization-input' }}
            sx={{
              fontFamily: 'Roboto',
              height: '34px',
              backgroundColor: colors.bluegrey[25],
              borderRadius: '2px',
              color: colors.primary[500],
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              fontWeight: '500',
            }}
          >
            {organizationsList?.map((orga) => {
              const orgaInfo = organizationOptions.find((o) => o.id === orga);
              const orgaLabel = orgaInfo ? orgaInfo.label : `[${orga}]`;
              return (
                <MenuItem key={orga} value={orga}>
                  {orgaLabel}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        {isLargeTopbar ? (
          <Box
            sx={{
              ...(!isSmall && { pr: 1 }),
              fontFamily: 'Roboto',
              fontSize: '0.75rem',
              mr: 1,
            }}
          >
            <Box>
              UTC Time:{' '}
              <Typography
                component="span"
                sx={{
                  fontSize: '0.75rem',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}
              >
                {timeStrings.UTCTime}
              </Typography>
            </Box>
            <Box>
              Your Time:{' '}
              <Typography
                component="span"
                sx={{
                  fontSize: '0.75rem',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}
              >
                {timeStrings.localTime}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography
              aria-haspopup="true"
              onClick={handleClick}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <IconButton
                sx={{
                  color: colors.bluegrey[800],
                }}
              >
                <AccessTimeIcon />
              </IconButton>
            </Typography>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              aria-labelledby="time-pop"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box
                sx={{
                  fontFamily: 'roboto',
                  borderRadius: '2px',
                  p: 2,
                  m: 2,
                }}
              >
                UTC Time:
                <Typography sx={{ fontWeight: '500' }}>
                  {timeStrings.localTime}
                </Typography>
                <Box sx={{ height: 16 }} />
                Your Time:
                <Typography sx={{ fontWeight: '500' }}>
                  {timeStrings.UTCTime}
                </Typography>
              </Box>
            </Popover>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
